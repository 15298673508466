import { Injectable, signal } from '@angular/core';
import { UAParser } from 'ua-parser-js';

/** Available application platform. */
export type WndrPlatform = 'web' | 'mobile';

/**
	* Service contains information about the platform on which the project is launched.
	* This service does not depend on anything,
	* so the current platform is set in a `app.component.ts` file for each application.
	*/
@Injectable({ providedIn: 'root' })
export class PlatformService {
	private readonly platform = signal<WndrPlatform | null>(null);

	private readonly platformInformation = UAParser(navigator.userAgent);

	/** Operating system. Return `undefined` if can't be recognize. */
	public get os(): string | undefined {
		return this.platformInformation.os.name;
	}

	/** If current platform a desktop. */
	public get isDesktop(): boolean {

		/*
			User agent doesn't provide enought information to detect if it's a desktop or not.
			You can find more information here: https://github.com/faisalman/ua-parser-js/issues/182.
			The approach we use to detect a desktop is to check if it's not android or iOS.
		*/
		const os = this.platformInformation.os.name;
		return os !== 'Android' && os !== 'iOS' && !this.isIPad;
	}

	/** Whether current device is tablet. */
	public get isTablet(): boolean {
		return this.platformInformation.device.type === 'tablet' || this.isIPad;
	}

	/** Get platform. */
	public get(): WndrPlatform {
		const platform = this.platform();
		if (platform === null) {
			throw Error('Platform must be specified in `app.component.ts` before getting.');
		}

		return platform;
	}

	/** Whether current platform is mobile or not. */
	public isMobile(): boolean {
		return this.get() === 'mobile';
	}

	/** Whether current platform is web or not. */
	public isWeb(): boolean {
		return this.get() === 'web';
	}

	/** Mark platform as web. */
	public markPlatformAsWeb(): void {
		this.checkAndThrowAlreadySetValueError();
		this.platform.set('web');
	}

	/** Mark platform as mobile. */
	public markPlatformAsMobile(): void {
		this.checkAndThrowAlreadySetValueError();
		this.platform.set('mobile');
	}

	private checkAndThrowAlreadySetValueError(): void {
		if (this.platform() !== null) {
			throw Error('The platform has already been set. Can\'t change platform.');
		}
	}

	/**
	 * Check if the current device is an iPad.
	 * @description This method is used to detect iPadOS because it's user agent contains macos string.
	 * See https://github.com/faisalman/ua-parser-js/issues/671#issuecomment-1735528594.
	 */
	private get isIPad(): boolean {
		const os = this.platformInformation.os.name;

		return os === 'Mac OS' && 'ontouchend' in document;
	}
}
